import React, { useEffect, useState } from "react";
import axios from "axios";

const DuplicateModal = ({ duplicateModalHandler, isDuplicateValue }) => {
  const [duplicateData, setDuplicateData] = useState([]);

  // IP 또는 전화번호 형식에 따라 필드 구분
  const getFieldType = (value) => {
    if (value.includes(".")) {
      return { field: "ip", value };
    } else {
      return { field: "phone", value };
    }
  };

  // 중복 데이터 조회
  const fetchDuplicateData = async () => {
    const fieldType = getFieldType(isDuplicateValue);

    if (!fieldType) {
      console.error("유효하지 않은 IP 또는 전화번호 형식입니다.");
      return;
    }

    try {
      const response = await axios.post("/api/customer/duplicate", {
        [fieldType.field]: fieldType.value,
      });
      setDuplicateData(response.data);
    } catch (error) {
      console.error("중복 데이터를 가져오는 중 오류가 발생했습니다.", error);
    }
  };

  // 모달이 열릴 때 데이터를 가져오기
  useEffect(() => {
    if (isDuplicateValue) {
      fetchDuplicateData();
    }
  }, [isDuplicateValue]);

  return (
    <div className="duplicate-modal-overlay" onClick={duplicateModalHandler}>
      <div
        className="duplicate-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="duplicate-modal-close"
          onClick={duplicateModalHandler}
        >
          &times;
        </button>
        <h2 className="duplicate-modal-title">중복된 데이터 목록</h2>
        <table className="duplicate-modal-table">
          <thead>
            <tr>
              <th>병원 이름</th>
              <th>광고 회사</th>
              <th>광고 제목</th>
              <th>이벤트 이름</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>IP</th>
              <th>날짜</th>
            </tr>
          </thead>
          <tbody>
            {duplicateData.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.hospital_name}</td>
                <td>{customer.advertising_company}</td>
                <td>{customer.ad_title}</td>
                <td>{customer.event_name}</td>
                <td>{customer.name}</td>
                <td>{customer.phone ? customer.phone.split("T")[0] : ""}</td>
                <td>{customer.ip}</td>
                <td>{customer.date ? customer.date.split(".")[0] : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* CSS */}
      <style jsx>{`
        .duplicate-modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .duplicate-modal-content {
          background: #fff;
          padding: 20px;
          border-radius: 8px;
          width: 1200px;
          max-width: 90%;
          position: relative;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          overflow-y: auto;
          max-height: 80vh;
        }
        .duplicate-modal-close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          background: none;
          border: none;
          cursor: pointer;
        }
        .duplicate-modal-title {
          margin: 0 0 20px;
          font-size: 1.5em;
          text-align: center;
        }
        .duplicate-modal-table {
          width: 100%;
          border-collapse: collapse;
        }
        .duplicate-modal-table th,
        .duplicate-modal-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: center;
        }
        .duplicate-modal-table th {
          background-color: #f2f2f2;
          font-weight: bold;
          color: #555;
        }
        .duplicate-modal-table tbody tr:nth-child(even) {
          background-color: #f9f9f9;
        }
        .duplicate-modal-table tbody tr:hover {
          background-color: #f1f1f1;
        }
      `}</style>
    </div>
  );
};

export default DuplicateModal;
