import React from "react";
import "./alertModal.scss";

const AlertModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="alert-modal">
      <div className="alert-modal-content">
        <img
          className="alert-modal-icon"
          src={`${process.env.PUBLIC_URL}/images/warning_icon.png`}
          alt="경고 아이콘"
        />
        <p className="alert-modal-message">{message}</p>
        <div className="alert-modal-actions">
          {onCancel && (
            <button className="alert-modal-button cancel" onClick={onCancel}>
              취소
            </button>
          )}
          {onConfirm && (
            <button className="alert-modal-button confirm" onClick={onConfirm}>
              확인
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertModal;

//사용예시
{
  /*
   <AlertModal 
  message="정말로 계속하시겠습니까?" 
  onConfirm={() => console.log("확인 클릭됨")}
  onCancel={() => console.log("취소 클릭됨")}
/>
 */
}
