import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function ExcelTemp({ customers }) {
  // 엑셀 파일로 데이터 추출하고 다운로드하는 함수
  const exportToExcel = () => {
    // 데이터를 원하는 형식으로 매핑
    const formattedData = customers.map((customer) => ({
      병원명: customer.hospital_name,
      매체: customer.advertising_company,
      광고제목: customer.ad_title,
      이벤트명: customer.event_name,
      이름: customer.name,
      번호: customer.phone,
      ip: customer.ip,
      일자: new Date(customer.date).toISOString().split("T")[0], // 날짜 형식을 YYYY-MM-DD로 변환
    }));

    // 매핑된 데이터를 엑셀 시트로 변환
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
    const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "customers.xlsx");
  };

  return (
    <div>
      <button className="excel-button" onClick={exportToExcel}>
        <img
          src={process.env.PUBLIC_URL + "/images/excel.png"}
          alt="엑셀다운로드"
          className="excel-icon"
        />
      </button>
    </div>
  );
}

export default ExcelTemp;
