import React, { useState, useEffect } from "react";
import axios from "axios";
import AlertModal from "../../components/AlertModal";
import DateFilter from "./DateFilter";

function ExpenseHistoryList({ formData, searchStatus, setSearchStatus }) {
  const [codes, setCodes] = useState([]);
  const [adSpendings, setAdSpendings] = useState({});
  const [selectedRows, setSelectedRows] = useState([]); // 선택된 행 상태
  const [editingCodeId, setEditingCodeId] = useState(null); //수정가능아이디

  //페이지네이션
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
  const [itemsPerPage] = useState(10); // 페이지당 항목 수

  //경고 모달
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 표시 여부 상태

  console.log(formData);
  console.log(formData.search_hospital_name_id);

  const fetchUrlCodeSettings = async () => {
    try {
      const response = await axios.get("/api/adexpense/list-adexpense", {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search_title: formData.search_title || "",
          search_hospital_name_id: formData.search_hospital_name_id || "",
        },
      });

      if (response.data) {
        setCodes(response.data.expensesWithDetails);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      }
      setSearchStatus(false);
    } catch (error) {
      console.error("Failed to fetch url code settings:", error);
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toISOString().split("T")[0];
    const timePart = date.toISOString().split("T")[1].split(".")[0];
    return `${datePart} ${timePart}`; // 날짜와 시간 사이에 띄어쓰기 추가
  };

  const handleAdspendChange = (id, value) => {
    // ',' 제거 후 숫자로 변환
    const numericValue = value.replace(/,/g, "");
    if (!isNaN(numericValue)) {
      setAdSpendings({
        ...adSpendings,
        [id]: {
          ...adSpendings[id],
          ad_spend: numericValue,
        },
      });
      handleCodeChange(id, "ad_spend", numericValue); // 코드의 ad_spend 필드도 함께 변경
    }
  };

  useEffect(() => {
    fetchUrlCodeSettings();
  }, [formData.search_hospital_name_id, currentPage]);

  useEffect(() => {
    if (searchStatus) {
      fetchUrlCodeSettings();
    }
  }, [searchStatus]);

  // 페이지 네이션
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // 페이지 변경
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
    console.log(selectedRows);
  };

  // 개별 유저 필드 수정 핸들러
  const handleCodeChange = (id, field, value) => {
    setCodes((prevCodes) =>
      prevCodes.map((code) =>
        code.id === id ? { ...code, [field]: value } : code
      )
    );
  };

  // 광고비 수정 버튼 핸들러
  const handleEdit = (id) => {
    setEditingCodeId(id);
  };

  const handleEditSave = async (codeId) => {
    const codeToUpdate = codes.find((code) => code.id === codeId);

    const updateFields = {
      user_id: 1, // 실제 사용자 ID로 교체 필요
      url_code_setting_id: codeId,
      ad_spend: Number(codeToUpdate.ad_spend),
      spend_date: new Date(codeToUpdate.spend_date).toISOString(),
      create_at: new Date().toISOString(), // 현재 시간을 포함한 수정 일자
    };
    console.log(updateFields);

    try {
      const response = await axios.put(
        `/api/adexpense/update/${codeId}`,
        updateFields
      );

      if (response.status === 200) {
        alert("광고비가 성공적으로 업데이트되었습니다.");

        // 수정 일자 갱신
        setCodes((prevCodes) =>
          prevCodes.map((code) =>
            code.id === codeId
              ? { ...code, updated_at: updateFields.updated_at }
              : code
          )
        );

        setEditingCodeId(null); // 수정 모드 해제
      } else {
        throw new Error("Failed to save");
      }
    } catch (error) {
      console.error("Failed to save ad spending:", error);
      alert("광고비 저장에 실패했습니다.");
    }
  };

  // 선택 삭제 함수
  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      alert("삭제할 항목을 선택하세요.");
      return;
    }

    try {
      const response = await axios.delete("/api/adexpense/delete", {
        data: { ids: selectedRows },
      });

      if (response.status === 200) {
        alert("선택한 항목이 삭제되었습니다.");
        setCodes((prevCodes) =>
          prevCodes.filter((code) => !selectedRows.includes(code.id))
        );
        setSelectedRows([]); // 선택된 항목 초기화
        toggleModal();
      } else {
        throw new Error("삭제 실패");
      }
    } catch (error) {
      console.error("Failed to delete selected rows:", error);
      alert("선택 삭제에 실패했습니다.");
    }
  };

  // 모달 토글 함수
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="expenseHistory">
      <div className="dateFilterdeleteExpense">
        <div>
          소진날짜
          {/* <DateFilter /> */}
        </div>

        <button className="deleteBtn" onClick={toggleModal}>
          선택삭제
        </button>

        {/* 모달 표시 */}
        {isModalOpen && (
          <AlertModal
            message="현재 선택한 코드를 정말 삭제하시겠습니까?"
            onConfirm={handleDelete}
            onCancel={toggleModal}
          />
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th>선택</th>
            <th>광고 제목</th>
            <th>매체</th>
            <th>광고비</th>
            <th>소진 일자</th>
            <th>수정 일자</th>
            <th>작성자</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {codes &&
            codes.map((code) => (
              <tr key={code.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(code.id)}
                    onChange={() => handleCheckboxChange(code.id)}
                  />
                </td>
                <td>{code.ad_title}</td>
                <td>{code.advertising_company_name}</td>
                <td>
                  <input
                    type="text"
                    name="ad_spend"
                    value={
                      editingCodeId === code.id
                        ? formatNumber(
                            adSpendings[code.id]?.ad_spend || code.ad_spend
                          )
                        : formatNumber(code.ad_spend)
                    }
                    disabled={editingCodeId !== code.id} // 수정 모드일 때만 활성화
                    onChange={(e) =>
                      handleAdspendChange(code.id, e.target.value)
                    }
                    placeholder="광고비"
                  />
                </td>
                <td>
                  <input
                    type="date"
                    name="spend_date"
                    value={formatDate(code.spend_date)}
                    disabled={editingCodeId !== code.id} // 수정 모드일 때만 활성화
                    onChange={(e) =>
                      handleCodeChange(code.id, "spend_date", e.target.value)
                    }
                  />
                </td>

                <td>
                  <span>{formatDateTime(code.created_at)}</span>
                </td>
                <td>{code.user_name}</td>
                <td>
                  {editingCodeId === code.id ? (
                    <button
                      className="save-button"
                      onClick={() => handleEditSave(code.id)}
                    >
                      저장
                    </button>
                  ) : (
                    <button
                      className="edit-button"
                      onClick={() => handleEdit(code.id)}
                    >
                      수정
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
}

export default ExpenseHistoryList;
